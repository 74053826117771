<template>
<div>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    class="dialog"
    :before-close="handleClose">
      <div class="modal-close" @click="handleClose"></div>
      <div id="modal_box">
        <div class="container">
          <div class="box">
            <div class="img_box">
              <img src="../../assets/icon/img.png" />
            </div>
            <h3>-10% GRATIS</h3>
            <p class="p3">
              HAZ TU PRIMER PEDIDO Y OBTÉN UN -10% DE DESCUENTO</br>
Regístrate y obtén acceso anticipado a la rebajas, novedades, promociones y más
            </p>
            <button @click="Link">Registrarse</button>
          </div>
          <div class="checkbox">
              <el-checkbox @change="adpop" v-model="checked">No volver a enseñar este mensaje</el-checkbox>
            </div>
        </div>
      </div>
    </el-dialog>
    <!-- 登录注册抽屉 -->
		<el-drawer class="r2l" :wrapperClosable="false" append-to-body :size="size" :visible.sync="showlogin"
			direction="rtl">
			<Login type="regist" v-if="showlogin"></Login>
		</el-drawer>
</div>
</template>

<style lang="less">
  @border-color: #ebebeb;
  @body-text_color: #333333;
  @primary-color: #fed100;
  @bg-white_color: #fff;
  @bg-nobel_color: #999999;
  @heading-color: #333;

  @media screen and (min-width: 1024px) {
    .el-dialog{
      width: 32%;
    }
  }
  @media screen and (max-width: 500px) {
    .el-dialog{
      width: 100%;
    }
  }

  h3{
    font-family: 'Work Sans', sans-serif;
    color: #333333;
    line-height: 1;
    font-weight: 700;
    font-size: 1.75rem;
  }

  .p3 {
    margin-top: 0;
    margin-bottom: 1rem;
    text-align:center;
    font-size: 16px;
    color: #000;
  }

  .checkbox{
    padding-left: 20px;
  }

  #add-address {

    #modal_box {
      position: relative;
    }
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 14px 30px 14px;

    .img_box {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        margin: 0;
        width: 150px;
      }
    }

    h3 {
      margin: 30px 0;
    }

    button {
      width: 180px;
      height: 50px;
      border-radius: 30px;
      border: 3px solid #fed200;
      // background-color: #FED200;
    }
  }

</style>
<script>
  import Vue from 'vue'
  import Login from "@/components/Base/login";
  import { setCoupon } from '@/utils';
  import {
    mapState
  } from 'vuex'

  export default {
    components: {Login},
    data() {
      return {
        size: '100vw',
        dialogVisible: false,
        showlogin: false,
        checked: false
      }
    },
    props: {
      
    },

    watch: {
      couponBoxOpen(e) {
        if (e && !this.user) {
          this.dialogVisible = true
        } else {
          this.dialogVisible = false
        }
      }
    },

    mounted(){
      if(document.documentElement.clientWidth >= 1024){
        this.size = '40vw'
      }
      if(document.documentElement.clientWidth <= 540){
        this.size = '80vw'
      }
      if(!this.user){
        this.dialogVisible = true
      }
    },
    methods: {
      Link(){
        this.dialogVisible = false
        this.showlogin = true
      },
      handleClose(data = false) {
        // 恢复默认值
        this.dialogVisible = false
      },
      adpop() {
        console.log(this.checked)
        let pop = this.checked === true ? 1 : 0
        //localStorage.setItem("coupon", pop)
        setCoupon(pop)
      }
    }
  }

</script>
