<template>
  <div class="Albumnews">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="val in data" :key="val.id">
        <img @click="showIt(val.spuId)" class="bannerPic" :src="val.cover" />
        <div class="spuName text-align">{{val.spuName}}</div>
        <div class="price text-align">{{val.price}}€</div>
      </div>
    </div>
    <template v-if="data.length > num">
      <div class="swiper-button-next" @click="swiper.slidePrev()">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="swiper-button-prev" @click="swiper.slideNext()">
        <i class="el-icon-arrow-right"></i>
      </div>
    </template>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "Album",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    num:{
      type: Number,
      default: 0
    }
  },
  watch: {
    data() {
      setTimeout(() => {
        let num = this.num
        this.swiper = new Swiper(".Albumnews", {
          slidesPerView: num,
          spaceBetween: 10,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
    },
  },
  data() {
    return {
      swiper: {},
    };
  },
  created() {
    // 若img标签使用的是异步图片数据，需要在加载数据之后，加一句：
    // this.$previewRefresh();
    // 这样刷新之后，vue-photo-preview预览效果才起效
  },
  methods: {
    showIt(val) {
      console.log('------------', this.$store.state.platform, val)
      let url = this.$store.state.platform == 'pc' ? 'GoodsDetail' : 'Detail_Mobile'
					url += '?id=' + val
      this.$router.push(url)
      this.$emit("showIt", val);
    },
  },
};
</script>

<style lang="less" scoped>
.Albumnews {
  width: 100%;
  height: 100%;
  position: relative;
  .bannerPic {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    line-height: 40px;
    color: #000;
    font-size: 12px;
    font-weight: bolder;
    top: 45%;
    z-index: 9;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .swiper-button-next {
    left: 10px;
  }
  .swiper-button-prev {
    right: 10px;
  }
  .text-align{
    text-align: center;
  }
  .spuName{
    padding: 10px 10px 0;
  }
  .price{
    font-size: 12px!important;
    padding: 10px;
  }
}
</style>
