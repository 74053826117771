<template>
  <div class="Album">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="val in data" :key="val.id">
        <img  @click="linkimg(val)" class="bannerPic" :src="val.img" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "Album",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    data() {
      setTimeout(() => {
        this.swiper = new Swiper(".Album", {
          slidesPerView: 1,
          spaceBetween: 10
        });
      });
    },
  },
  data() {
    return {
      swiper: {},
    };
  },
  created() {
    // 若img标签使用的是异步图片数据，需要在加载数据之后，加一句：
    // this.$previewRefresh();
    // 这样刷新之后，vue-photo-preview预览效果才起效
  },
  methods: {
    linkimg(item) {
		console.log('linkimg----', item)
		if(item.linkType=='category' && item.linkId) {
      if(item.linkId){
			  this.$router.push('/GoodsList?cateId='+item.linkId)
      }
		}
		else if (item.linkType=='goods'){
      if(item.linkId){
        let url = this.$store.state.platform == 'pc' ? '/GoodsDetail' : '/Detail_Mobile' 
        url  =  url + '?id=' + item.linkId; 
        this.$router.push(url)
      }
		}
		else if (item.linkType=='goodsSeries') {
			// Api.Goods.recommand({
			// 	spu_ids: item.linkId
			// }).then((res) => {
			// 	console.log( 'goodsSeries------', res.data );
			// });
      if(item.linkId){
			  this.$router.push('/Adlink?spu_ids='+item.linkId);
      }
		}
	}
  },
};
</script>

<style lang="less" scoped>
.Album {
  width: 100%;
  height: 100%;
  position: relative;
  .bannerPic {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    line-height: 40px;
    color: #000;
    font-size: 12px;
    font-weight: bolder;
    top: 45%;
    z-index: 9;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .swiper-button-next {
    left: 10px;
  }
  .swiper-button-prev {
    right: 10px;
  }
  .text-align{
    text-align: center;
  }
  .spuName{
    padding: 10px 10px 0;
  }
  .price{
    font-size: 12px!important;
    padding: 10px;
  }
}
</style>
