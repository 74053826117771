<template>
	<div class="Home">
		<!-- PC端banner -->
		<BannerPc v-if="$store.state.platform == 'pc'" :data="ad"></BannerPc>
		<BannerMob v-else :data="ad"></BannerMob>
		<!-- 中间的几个大广告 -->
		<div class="ad-grid">
			<div class="ad-dev" v-for="item in middlepics" :key="item.adId">
				<img class="ad-img" @click="linkimg(item)" :src="item.img" />
				<div class="ad-text">{{item.adName}}</div>
			</div>
			<div class="ad-dev" v-for="item in catepics" :key="item.adId">
				<img class="ad-img" @click="linkimg(item)" :src="item.img" />
				<div class="ad-text">{{item.adName}}</div>
			</div>
			<div class="ad-dev" v-for="item in footpics" :key="item.adId">
				<img class="ad-img" @click="linkimg(item)" :src="item.img" />
				<div class="ad-text">{{item.adName}}</div>
			</div>
		</div>
		<h4 class="album-title margin-top">{{labelarr[2]?labelarr[2].label_name:''}}</h4>
		<div class="album">
			<Albumnews :num="clientWidth" :data="news"></Albumnews>
		</div>
		<div class="btndev"><button class="btn" @click="product(1)">Descubre más</button></div>
		<h4 class="album-title margin-top">{{labelarr[1]?labelarr[1].label_name:''}}</h4>
		<div  class="album">
			<Albumrec :num="clientWidth" :data="recommand"></Albumrec>
		</div>
		<div class="btndev margin-bottom"><button class="btn" @click="product(2)">Descubre más</button></div>
		
		<!-- sku弹窗 -->
		<div @click.stop="() => {}">
			<el-dialog title="Talla" :visible.sync="listVisible" width="30%" :before-close="
          (done) => {
            done();
          }
        ">
				<div class="list-content">
					<p class="list-sku" v-for="(item, index) in skus" :key="index" @click.stop="
              () => {
                $bus.$emit('addCart', item.sku_id);
                listVisible = false;
              }
            ">
						{{ item.spec_info.replace(/Talla:/g, "") }}
					</p>
				</div>
			</el-dialog>
		</div>
		<coupon v-if="is_coupon==0" />
	</div>
</template>

<script>
	import { getCoupon } from '@/utils';
	import BannerPc from "./Banner/banner_pc.vue";
	import BannerMob from "./Banner/banner_mob.vue";
	import coupon from "./coupon";
	import Api from "../../api/request.js";
	import Albumrec from "./albumrec.vue";
	import Albumnews from './albumnews.vue';

	export default {
		name: "Home",
		components: {
			BannerPc,
			BannerMob,
			coupon,
			Albumrec,
			Albumnews
		},
		data() {
			return {
				is_coupon: null,
				recommand: [],
				news: [],
				ad: [],
				middlepics: [],
				footpics: [],
				catepics: [],
				pics: {
					pic1: "",
					pic2: "",
					pic3: "",
					pic4: "",
					pic5: "",
					pic6: "",
				},
				listVisible: false,
				skus: [],
				labelarr: [],
				clientWidth: 0
			};
		},
		created() {
			let is = getCoupon()
			let that = this
			this.clientWidth = document.documentElement.clientWidth < 500 ? 1: 4

			setTimeout(function() {
				// 需要执行的代码		
				that.is_coupon = is != undefined ? is : 0
				console.log('需要执行的延迟代码--------', that.is_coupon)
			}, 30000); // 定时时间

			Api.Index.label().then((res) => {
				this.labelarr = res.data.data
			});

			Api.Goods.list({
				label_id: 3,
				page_limit: 10
			}).then((res) => {
				this.recommand = res.data.data?res.data.data.list:[];
			});

			Api.Goods.list({
				label_id: 5,
				page_limit: 10
			}).then((res) => {
				this.news = res.data.data?res.data.data.list:[];
			});
			

			this.getPageData();
		},
		methods: {
			showIt(val) {
				let url = this.$store.state.platform == 'pc' ? 'GoodsDetail' : 'Detail_Mobile'
					url += '?id=' + val
      			this.$router.push(url)
			},
			getPageData() {
				//获取推荐商品轮播图等数据
				//this.$loading.start();
				Promise.all([
					new Promise((resolve, reject) => {
						// Api.Index.recommand({
						// 	limit: 10,
						// 	label: 4
						// }).then((res) => {

						// 	this.recommand = res.data.data?res.data.data.list:[];
						// 	resolve();
						// });
					}),
					new Promise((resolve, reject) => {
						Api.Index.advertisement({
							type: "index"
						}).then((res) => {
							this.ad = res.data.data.pcIndex1?res.data.data.pcIndex1.ads:[];
							this.middlepics = res.data.data.pcIndex2?res.data.data.pcIndex2.ads:[];
							this.footpics = res.data.data.pcIndex4?res.data.data.pcIndex4.ads:[];
							this.catepics = res.data.data.pcIndex3?res.data.data.pcIndex3.ads:[];
							resolve();
						});
					}),
					new Promise((resolve, reject) => {
						// Api.Index.pics({
						// 	type: "indexs"
						// }).then((res) => {

						// 	resolve();
						// });
					}),
				]).then((res) => {
					//this.$loading.done();
				});
			},
			handleAddCart(val) {
				if (val.skus && val.skus.length == 1) {
					this.$bus.$emit("addCart", val.skus[0].sku_id);
				} else {
					this.skus = val.skus;
					this.listVisible = true;
				}
			},
			linkimg(item) {
				if(item.linkUrl){
					window.open(item.linkUrl)
				}else{
					if(item.linkType=='category') {
						if(item.linkId){
							this.$router.push('/GoodsList?cateId='+item.linkId)
						}
					}
					else if (item.linkType=='goods'){
						if(item.linkId){
							let url = this.$store.state.platform == 'pc' ? '/GoodsDetail' : '/Detail_Mobile' 
							url  =  url + '?id=' + item.linkId; 
							this.$router.push(url)
						}
					}
					else if (item.linkType=='goodsSeries') {
						if(item.linkId){
							this.$router.push('/Adlink?spu_ids='+item.linkId);
						}
					}
				}
			},
			product(type){
				this.$router.push('/GoodsNews?type='+type)
			}
		},
	};
</script>

<style lang="less" scoped>
	.hot {
		margin-top: 3px;

		.line1 {
			margin-bottom: 3px;
			display: flex;
			justify-content: space-between;

			.left {
				width: 37.8vw;
				height: 38vw;
				background: #ccc;
				margin-right: 3px;
			}

			.right {
				flex: 1;
				height: 38vw;
				background: #ccc;
			}
		}

		.line2 {
			height: 31.25vw;
			background: #ccc;
		}
	}
	.ad-grid{
		display: flex;
		flex-wrap: wrap;
		margin-left: 4px;
	}
	.ad-dev{
		width: 33%;
		margin: 1px;
		position: relative;
	}
	.ad-img{
		width: 100%;
		height: 600px;
		object-fit: cover;
  		flex: 1;
		display: block;
	}
	.ad-text{
		position:absolute;
		bottom: 0;
		padding: 20px;
    	color: #5e5b5b;
	}
	.list-content {
		width: 100%;

		.list-sku {
			width: 100%;
			border-bottom: 1px solid #eee;
			line-height: 35px;
			height: 35px;
			cursor: pointer;
		}
	}

	/deep/.el-dialog__body {
		padding-top: 0px;
	}

	.selTitle {
		height: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.day {
			font-size: 16px;
			font-family: GillSans;
			font-weight: 600;
			line-height: 19px;
		}

		.line {
			width: 74px;
			height: 1px;
			background: #000000;
			margin: 10px 0;
		}

		.month {
			font-size: 16px;
			font-family: GillSans;
			font-weight: 600;
			line-height: 19px;
		}

		.text {
			text-align: center;
			font-size: 60px;
			font-weight: 600;
			line-height: 72px;
		}
	}

	.selected {
		display: flex;
		flex-wrap: wrap;

		.item {
			width: calc((100vw - 18px) / 4);
			cursor: pointer;
			margin-right: 6px;

			&:nth-child(4n) {
				margin-right: 0;
			}

			&:nth-child(n + 5) {
				margin-top: 40px;
			}

			.pic {
				height: 29.1vw;
				background: #d8d8d8;
			}

			.name {
				font-size: 18px;
				font-weight: 600;
				line-height: 25px;
				margin: 30px 0 8px;
				text-align: center;
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.subName {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
			}

			.price {
				font-size: 18px;
				font-weight: bold;
				line-height: 25px;
				margin: 70px 0 30px;
				text-align: center;
			}

			.addCart {
				width: 160px;
				height: 40px;
				background: #323232;
				border-radius: 6px;
				text-align: center;
				line-height: 40px;
				font-size: 14px;
				font-family: GillSans;
				font-weight: 600;
				color: #ffffff;
				margin: 0 auto;
			}
		}
	}

	.recien {
		.title {
			height: 232px;
			text-align: center;
			line-height: 232px;
			font-size: 60px;
			font-weight: 600;
			color: #000000;
		}

		.block {
			.line1 {
				height: 24.5vw;
				background: #d8d8d8;
				margin-bottom: 3px;
			}

			.line2 {
				display: flex;
				justify-content: space-between;

				.item {
					width: calc((100vw - 3px) / 2);
					overflow: hidden;

					.pic {
						width: 100%;
						height: 24.5vw;
						background: #d8d8d8;
					}

					.sex {
						font-size: 36px;
						font-weight: 600;
						color: #000000;
						line-height: 43px;
						text-align: center;
						margin: 40px 0 80px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		.recommanddev{
			height: 440px;
		}
		.recommandPic {
			width: 100%;
			height: 420px;
			object-fit: cover;
			flex: 1;
			display: block;
		}
		.ad-grid{
			display: block;
			margin-left: 0;
		}
		.ad-dev{
			width: 100%;
			position: relative;
		}
		.ad-img{
			width: 100%;
			height: 420px;
		}

		.selTitle {
			height: 200px;

			.text {
				font-size: 22px;
				line-height: 45px;
			}
		}

		.selected {
			flex-wrap: wrap;

			.item {
				width: calc((100vw - 6px) / 2);
				padding-bottom: 20px;

				&:nth-child(2n) {
					margin-right: 0;
				}

				.pic {
					height: 55vw;
				}

				.name {
					margin: 3vw 0 2vw;
				}

				.price {
					margin: 2vw 0;
				}

				.addCart {
					width: 130px;
					line-height: 42px;
				}
			}
		}

		.recien {
			.title {
				font-size: 22px;
				height: 100px;
				line-height: 100px;
			}

			.block {
				.sex {
					font-size: 22px !important;
					line-height: 40px !important;
					margin: 20px 0 40px !important;
				}
			}
		}
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 600px;
		margin: 0;
	}
	
	// .el-carousel__item:nth-child(2n) {
	// 	background-color: #99a9bf;
	// }
	
	// .el-carousel__item:nth-child(2n+1) {
	// 	background-color: #d3dce6;
	// }
	.recommanddev{
		height: 640px;
	}
	.recommandPic{
		width: 100%;
		height: 600px;
		object-fit: cover;
	}
	
	.album-title{
		height: 50px;
		text-align: center;
	}
	.margin-top-xs{
		margin-top: 2px;
	}
	.margin-top{
		margin-top: 20px;
	}
	.margin-bottom{
		margin-bottom: 20px;
	}
	.btndev{
		text-align: center;
	}
	.btn{
		background: #000;
		color: #fff;
		padding: 10px 20px;
		cursor: pointer;
	}
</style>
<style>
	.el-carousel__indicators--outside{
		position: absolute;
		bottom: 0;
	}
</style>